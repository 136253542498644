import request from '@/utils/request'

// audit
export function audit(data) {
  return request({
    url: 'community/crm/auditWithdrawalsRecord',
    method: 'put',
    data
  })
}
