<template>
    <el-dialog
      title="拒绝转出"
      v-if="dialogShow"
      :visible="dialogShow"
      :append-to-body="true"
      width="500px"
      :before-close="beforeCloseHandle"
    >
      <div class="content">
        <div class="line">
          <span class="mar-right10">客户昵称: </span>
          <span>{{userInfo.userName}}</span>
        </div>
        <div class="line">
          <span class="mar-right10">转出金额 (USDT): </span>
          <span> {{userInfo.amount}}</span>
        </div>
        <div class="line">
          <span class="mar-right10">申请时间: </span>
          <span> {{$parseTime(userInfo.time)}}</span>
        </div>
        <div class="line">
          <span class="mar-right10">拒绝原因:  </span>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
          >
            <el-form-item prop="reason">
              <el-input
                class="textarea-el"
                v-model="form.reason"
                type="textarea"
                placeholder="请输入"
                maxlength="20"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="footer-btn" slot="footer">
        <el-button type="text"
                   @click="cancelHandle"
        >取消</el-button>
        <el-button type="primary"
                   @click="sureHandle"
        >确认</el-button>
      </div>
    </el-dialog>
</template>

<script>
  import { audit } from '@/api/financial/mentionMoneyManage'
  export default {
    name: 'rejectDialog',
    props:{
      dialogShow:{
        type: Boolean,
        default: false,
      },
      userInfo:{
        type: Object,
        default(){
          return {

          }
        }
      }
    },
    data() {
      return {
        form: {
          reason: ''
        },
        rules: {
          reason: [
            { required: true, message: '请输入拒绝原因', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      beforeCloseHandle() {
        this.$emit('close-dialog')
      },
      cancelHandle(){
        this.beforeCloseHandle();
      },
      sureHandle(){
        this.$refs.form.validate((valid) => {
          console.log(valid)
          if(valid){
            let params = {
              id: this.userInfo.id,
              status: 2,
              reason: this.form.reason
            }
            audit(params).then(() => {
              this.$emit('reject-success');
            }).catch((error) => {
              this.beforeCloseHandle();
            })
          }else {
            console.log('no-pass')
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .content {
    line-height: 1.5;
    font-size: 14px;
    .line {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
      /deep/ .textarea-el {
        width: 300px;
      }
    }
  }
</style>
