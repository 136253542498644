<template>
  <div class="app-container equityheader summary-page">
    <eHeader ref="header" :query="query" />
    <!--表格渲染-->
    <el-table
      v-loading="loading"
      :data="data"
      size="small"
      stripe
      style="width: 100%;"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="siteName" label="社区名称"/>
      <el-table-column prop="userName" label="客户昵称"/>
      <el-table-column prop="amount" label="提币数量(USDT)"/>
      <el-table-column label="提币地址">
        <template slot-scope="scope">
          <span>
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.address"
                  placement="top"
                >
                  <span>{{getHandleAddress(scope.row.address)}}</span>
                </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间">
        <template slot-scope="scope">
          <span>{{getTime(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="channelProtocolNo" label="状态">
        <template slot-scope="scope">
          <span>{{getStatus(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          <span>{{getReason(scope.row)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="payHandle(scope.row)"
            :loading="payLoading"
          >支付</el-button>
          <el-button
            v-if="scope.row.status == 1"
            type="danger"
            size="mini"
            @click="rejectHandle(scope.row)"
          >拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>
    <reject-dialog
      ref="rejectdialog"
      :dialog-show="dialogShow"
      :user-info="currentUserInfo"
      @reject-success="rejectSuccess"
      @close-dialog="closeDialog"
    ></reject-dialog>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
  import { audit } from '@/api/financial/mentionMoneyManage'
  import rejectDialog from '@/components/financial/mentionMoneyManage/rejectDialog'
  import { parseTime } from '../../../utils'
  import initData from '@/mixins/initData'
  import eHeader from '@/components/financial/mentionMoneyManage/header'
  export default {
    components: {
      eHeader,
      rejectDialog,
    },
    mixins: [initData],
    data() {
      return {
        statistics: {
          "amount": '',// "总余额",
        },
        dialogShow: false,
        currentUserInfo:{
          userName: '',
          amount: '',
          time: '',
        },
        payLoading: false,
        rejectLoading: false,
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.init()
      });
    },
    methods: {
      beforeInit() {
        this.url = '/community/crm/getWithdrawalsRecord'
        this.params = {
          page: this.page,
          size: this.size,
          status: this.query.status,
          id: this.query.id,
        }
        if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
        }
        if(this.query.userName){
          this.params.userName = this.query.userName;
        }
        if(this.query.startTime){
          this.params.startTime = parseTime(this.query.startTime);
        }
        if(this.query.endTime){
          this.params.endTime = parseTime(this.query.endTime);
        }
        return true;
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        columns.forEach((column, index) => {
          switch (index) {
            case 0:
              sums[index] = '合计';
              break;
            case 3:
              sums[index] = `${this.statistics.amount}`;
              break;
          }
        });
        return sums;
      },
      getStatus(row){
        switch (row.status) {
          case 1:
            return '待支付';
          case 2:
            return '已拒绝';
          case 3:
            return '已支付';
        }
      },
      getTime(row){
        return parseTime(row.time);
      },
      getHandleAddress(address){
        return address.slice(0,4) + "****" + address.slice(-4);
      },
      getReason(row){
        return row.reason || '-';
      },
      closeDialog(){
        this.dialogShow = false;
      },
      payHandle(row){
        this.payLoading = true;
        audit({
          id: row.id,
          status: 3,
        }).then((res) => {
          this.payLoading = false;
          this.$message({
            type: 'success',
            message: '支付成功',
          });
          this.init();
        }).catch((erroe) => {
          this.payLoading = false;
        })
      },
      rejectHandle(row){
        this.$refs.rejectdialog.reason = '';
        this.currentUserInfo = row;
        this.dialogShow = true;
      },
      rejectSuccess(){
        this.dialogShow = false;
        this.$message({
          message: '操作成功',
          type: 'success',
        })
        this.init();
      }
    }
  }
</script>

<style lang="less" scoped>
.equityheader {

}
</style>
